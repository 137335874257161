/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 1023px) {

    .after_clear:after,.wrapper:after,section:after{clear:both;display: block;content:" "}
    .before_clear:before{clear:both;display: block;content:" "}

    .wrapper {width: 650px;}


    /* header
    ----------------------------------------------------------------------------------------------*/
   


    /* homepage
    ----------------------------------------------------------------------------------------------*/
    .slideHome {
        .slider {
            .slide {
                .images {
                    height: 100vh; min-height: 650px;
                }
                .caption {
                    .wrapper {width: 650px;}
                }
            }
        }
    }
    
    .side_mnu {
        .wrap_side {
            padding: 70px 70px 70px 45px;
            &:after {bottom: 140px;}
            .nav_side {
                ul {
                    li {
                        ul {
                            li {
                                a {font-size: 18px; margin-bottom: 15px;}
                            }
                        }
                    }
                }
            }
            .btm_side {
                .btm_src {
                    form {
                        input[type="text"] {font-size: 18px; padding: 10px 0;}
                    }
                }
                .lang {
                    a {font-size: 16px;}
                }
            }
        }
    }

    .home_business {
        max-height: none;
        .listing {
            width: 405px; display: block; margin: 70px auto 0; padding-top: 25px;background: url("../images/material/shadow_slide.png") no-repeat top center; background-size: 95%;
            .item {
                width: 405px; height: 405px; @include boxSizing(border-box); margin: 0;
                padding: 80px 45px 20px;
                h2 {
                    span{
                        &.ico {background-size: 100%; width: 45px; height: 40px; margin-right: 5px;}
                    }
                }
            }
        }
    }
    .home_about {
        min-height: 660px;
        .text_about {margin-left: 0;}
        .img_about {width: 450px;}
    }
    .home_wcu {
        .list_wcu {
            .col {
                width: 45%; margin-right: 9.5%;
                &:nth-of-type(3n) {margin-right: 9.5%;}
                &:nth-of-type(2n) {margin-right: 0;}
            }
        }
    }
    .home_contact {
        .text_contact {
            margin: 0;
            h1 {font-size: 39px;}
        }
    }

    /* middle
    ----------------------------------------------------------------------------------------------*/
    .pg_title {
        .map {
            @include transform(scale(0.7)); left: -40%;
            .pointer {
                span {
                    &.circle {bottom: -125px;}
                }
                &.p-1 {top: 265px;}
                &.p-2 {left: 835px; top: 200px}
                &.p-3 {top: 255px;}
                &.p-4 {top: 330px;}
                &.p-5 {top: 200px;}
            }
            .sub-loc {left: 165px;}
            .detail-loc-info {right: 90px;}
        }
        .img_bg {
            height: 560px;
            img {height: 100%; width: 100%; object-fit: cover;}
        }
        .txt_title_pg {
            h3 {font-size: 40px;}
        }
        &.scrollify {
            .img_bg {min-height: 480px; height: 95vh;}
        }
    }
    .inner_text {
        padding: 75px 0 0;
        .lf_txt,
        .rh_txt {float: none; width: auto; display: block; position: relative;}
        .lf_txt {margin-bottom: 25px;}
    }
    
    .top_sec {
        p {font-size: 16px; line-height: 24px;}
    }
    
    .next-read {
        margin-top: 50px;
        .wrapper {width: 650px; padding: 0;}
        .left {width: 255px;}
        .right {width: 345px;}
    }
    .service_list {
        margin-top: 40px;
        .item {
            .txt_item {
                margin-left: 85px;
                h6 {line-height: 18px;}
                p {max-height: 80px; overflow: hidden;}
            }
        }
    }
    .listing {
        &.about_sub {
            padding: 0;
            .item {
                padding-bottom: 140px;
                .dec {
                    padding: 25px 20px 18px;
                    h5 {font-size: 16px; line-height: 20px;}
                    p {font-size: 14px; line-height: 20px; height: 40px;}
                }
            }
        }
    }

    .content_service {
        .top_sec {width: 510px;}
    }
    .slider_market {
        .slide {
            margin: 0 15px;
            .img {
                img {width: 100%; display: block;}
            }
        }
    }
    .content_galery {
        .slider_galery {
            width: 585px; padding-top: 30px;
            .img {height: 275px; width: 585px;}
        }
        .slide {
            .text_slide {
                h6 {font-size: 18px; max-width: 70%;}
            }
        }
    }

    



    .wrap-popup {
        .box-popup {
            margin: 20% auto;
            width: 640px;
            .close {width: 45px; height: 45px; right: -22px;}
            .slider-1 {
                .slick-arrow {
                    z-index: 9;
                    &.slick-next {
                        right: -30px;
                        &:hover {right: -35px;}
                    }
                    &.slick-prev {
                        left: -30px;
                        &:hover {left: -35px;}
                    }
                }
            }
            .slider-2 {
                top: 330px; left: -30px; right: -30px;
                .box {height: 70px; width: 125px;}
                .slide {padding: 0 8px;}
            }
        }
    }

    /* footer
    ----------------------------------------------------------------------------------------------*/
    footer .copyright_txt {
        width: 284px;
        p {
            span {display: inline-block;}
        }
    }

}